import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BigWinService {
    private subject:BehaviorSubject<Array<any>> = new BehaviorSubject<any>([]);

    constructor(
        private apiService: ApiService
    ) {
        this.onInit()
    }

    public getData() {
        return this.subject.asObservable() 
    }

    public setData(data:any) {
        this.subject.next(data)
    }

    public addData(data:any) {
        const current = this.subject.getValue()
        current.shift(); // 1개 삭제
        current.push(data) // 추가
        this.subject.next(current);
    }

    private onInit() {
        this.apiService.get('/main/big_win', false).subscribe(jsondata => {
            if(jsondata.success) {
                this.subject.next(jsondata.data.items)
            }
        })
    }
}